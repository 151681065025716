import * as constants from "redux/constants";

const initialState = {
  checkingAuth: true,
  isAuth: false,
  user: null,
  serviceAvailable: true
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case constants.GET_USER_REQUEST:
      return {
        ...state,
        checkingAuth: true,
      };

    case constants.GET_USER_SUCCESS:
      return {
        ...state,
        checkingAuth: false,
        isAuth: true,
        user: payload,
      };

    case constants.SERVICE_UNAVAILABLE:
      return {
        ...state,
        checkingAuth: false,
        isAuth: true,
        serviceAvailable: false
      };

    case constants.GET_USER_FAIL:
      return {
        ...state,
        checkingAuth: false,
        isAuth: false,
      };

    default:
      return state;
  }
}
