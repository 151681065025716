import * as constants from "redux/constants";
import AbbieJPG from "assets/abbie.jpg";
import UserPNG from "assets/user.svg";
import { loadState } from "utils";

import * as data from "data";
import * as utils from "utils";

const prevChats = loadState("chats");

const initialState = {
  sessionChecking: false,
  loading: false,
  chats: prevChats ? prevChats : data.initialChat,
};

export default function chats(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case constants.CLEAR_CHATS:
      return {
        sessionChecking: false,
        loading: false,
        chats: data.initialChat,
      };
    case constants.GET_CHATS_REQUEST:
      return {
        ...state,
        sessionChecking: true,
      };

    case constants.GET_CHATS_SUCCESS:
      return {
        ...state,
        sessionChecking: false,
        chats: [...state.chats, ...payload],
      };

    case constants.GET_CHATS_FAIL:
      return {
        ...state,
        sessionChecking: false,
      };

    case constants.GENERAL_PRODUCT_REQUEST:
    case constants.GENERAL_STREAM_REQUEST:
      return {
        ...state,
        loading: true,
        chats: [
          ...state.chats,
          {
            id: state?.chats?.length + 1,
            name: data?.USER_NAME,
            avatar: UserPNG,
            message: !Array.isArray(payload) ? payload : undefined,
            isBot: false,
            createdAt: utils.getCurrentTimeStamp(),
            isLoading: false,
            options: !Array.isArray(payload) ? undefined : payload,
          },
          {
            id: "",
            name: data?.BOT_NAME,
            avatar: AbbieJPG,
            message: "",
            audio: "",
            isBot: true,
            createdAt: utils.getCurrentTimeStamp(),
            isLoading: true,
          },
        ],
      };

    case constants.GENERAL_ANSWER:
      const chats = state.chats;
      const chatId = payload.chatId;
      const userOptions = payload.options?.filter(
        (option) => option.chat === chatId
      );

      const formattedOptions = upperCaseFirstLetter(utils.formatOptions(userOptions));

      const userMessage = {
        id: "user_" + payload.bot.id,
        name: data?.USER_NAME,
        avatar: UserPNG,
        message: undefined,
        isBot: false,
        createdAt: utils.getCurrentTimeStamp(),
        isLoading: false,
        options: userOptions,
      };

      const botMessage = {
        id: payload.bot.id,
        name: data?.BOT_NAME,
        avatar: AbbieJPG,
        streamMessage:
          chatId === 1
            ? formattedOptions + " " + payload.bot.message
            : payload.bot.message,
        message: "",
        audio: "",
        isBot: true,
        createdAt: utils.getCurrentTimeStamp(),
        isLoading: true,
        options: payload.bot.options,
        multiSelect: payload.bot.multiSelect,
      };

      let newChats = chats;

      if (chatId === 1 || payload.chatOneLength === 0) {
        newChats = chats.slice(0, 1);
      } else if (chatId === 2 || payload.chatTwoLength === 0) {
        newChats = chats.slice(0, 3);
      }

      newChats =
        userOptions.length > 0
          ? [...newChats, userMessage, botMessage]
          : [...newChats];

      return {
        ...state,
        loading: false,
        chats: newChats,
      };

    case constants.GENERAL_PRODUCT_SUCCESS: {
      const clonechats = [...state.chats];
      const updatedchats = clonechats.map((e) => {
        return {
          ...e,
          id: e?.id === "" ? payload?.result?._id : e?.id,
          message: e?.id === "" ? payload?.result || data?.err : e?.message,
          audio: e?.id === "" ? payload?.audio : "",
        };
      });

      return {
        ...state,
        loading: false,
        chats: updatedchats,
      };
    }

    case constants.GENERAL_ANSWER_SUCCESS: {
      //  console.log("GENERAL_ANSWER_SUCCESS", payload)
      const clonechats = [...state.chats];
      const updatedchats = clonechats.map((e) => {
        return {
          ...e,
          isLoading: false
        };
      });

      return {
        ...state,
        loading: false,
        chats: updatedchats,
      };
    }
    case constants.GENERAL_ANSWER_UPDATE: {
      const clonechats = [...state.chats];
      clonechats.pop();
      clonechats.push(payload);

      return {
        ...state,
        loading: false,
        chats: clonechats,
      };
    }
    case constants.GENERAL_STREAM_UPDATE: {
      const clonechats = [...state.chats];
      const updatedchats = clonechats.map((e) => {
        return {
          ...e,
          message: e?.id === "" ? payload || data?.err : e?.message,
        };
      });

      return {
        ...state,
        loading: false,
        chats: updatedchats,
      };
    }
    case constants.GENERAL_STREAM_SUCCESS: {
      const clonechats = [...state.chats];
      const updatedchats = clonechats.map((e) => {
        return {
          ...e,
          id: e?.id === "" ? clonechats.length + 1 : e?.id,
          message: e?.id === "" ? payload || data?.err : e?.message,
          isLoading: false
        };
      });

      return {
        ...state,
        loading: false,
        chats: updatedchats,
      };
    }
    case constants.EMAIL_SUBSCRIPTION: {
      const clone = [...state.chats];
      const tmp = clone.filter((e) => !e?.isEmailRequest && e.isBot);
      const last = tmp[tmp.length - 1];

      utils.saveState("subscribed", true);
      const clonechats = [...clone,
      {
        id: state?.chats?.length + 1,
        name: data?.USER_NAME,
        avatar: UserPNG,
        message: undefined,
        isBot: false,
        createdAt: utils.getCurrentTimeStamp(),
        isLoading: false,
        options: [{ title: 'Yes', value: 'Yes', chat: 'email' }]
      }, {
        ...data.emailChatSuccess,
        id: state?.chats?.length + 2,
        message: data.emailChatSuccess.message + "\n\nSo, back to where we left off, " + getLastSentence(last?.message),
      }];
      return {
        ...state,
        loading: false,
        chats: clonechats,
      };
    } case constants.EMAIL_SUBSCRIPTION_DECLINED: {
      const clone = [...state.chats];
      const tmp = clone.filter((e) => !e?.isEmailRequest && e.isBot);
      const last = tmp[tmp.length - 1];
      const clonechats = [...clone,
      {
        id: state?.chats?.length + 1,
        name: data?.USER_NAME,
        avatar: UserPNG,
        message: undefined,
        isBot: false,
        createdAt: utils.getCurrentTimeStamp(),
        isLoading: false,
        options: [{ title: 'No', value: 'No', chat: 'email' }]
      }, {
        ...data.emailChatDeclined,
        id: state?.chats?.length + 2,
        message: data.emailChatDeclined.message + "\n\nSo, back to where we left off, " + getLastSentence(last?.message),
      }];
      
      return {
        ...state,
        loading: false,
        chats: clonechats,
      };
    }
    case constants.EMAIL_SUBSCRIPTION_REQUEST: {
      const clonechats = [...state.chats, {
        id: state?.chats?.length + 1,
        name: data?.USER_NAME,
        avatar: UserPNG,
        message: payload.message,
        isBot: false,
        createdAt: utils.getCurrentTimeStamp(),
        isLoading: false,
        options: !Array.isArray(payload) ? undefined : payload,
      }, {
        ...data.emailChatRequest,
        id: state?.chats?.length + 2,
        message: data.emailChatRequest.message.replace("[EMAIL]", payload.email),
        email: payload.email
      }];
      return {
        ...state,
        loading: false,
        chats: clonechats,
      };
    }
    case constants.GENERAL_PRODUCT_FAIL:
    case constants.GENERAL_STREAM_FAIL: {
      const clonechats = [...state.chats];
      const updatedchats = clonechats.map((e) => {
        return {
          ...e,
          id: e?.id === "" ? clonechats.length + 1 : e?.id,
          message: e?.id === "" ? data?.err : e?.message,
        };
      });

      return {
        ...state,
        loading: false,
        chats: updatedchats,
      };
    }

    case constants.GET_AUDIO_SUCCESS: {
      const clonechats = [...state.chats];
      const updatedchats = clonechats.map((e, _idx) => {
        return {
          ...e,
          audio: clonechats.length - 1 === _idx ? payload?.audio : e?.audio,
          isLoading: false,
        };
      });

      return {
        ...state,
        loading: false,
        chats: updatedchats,
      };
    }

    case constants.GET_AUDIO_FAIL: {
      const clonechats = [...state.chats];
      const updatedchats = clonechats.map((e, _idx) => {
        return {
          ...e,
          audio: clonechats.length - 1 === _idx ? "" : e?.audio,
          isLoading: false,
        };
      });

      return {
        ...state,
        loading: false,
        chats: updatedchats,
      };
    }

    default:
      return state;
  }
}
function lowerCaseFirstLetter(string) {
  //console.log(string);
  return string?.charAt(0).toLowerCase() + string?.slice(1);
}

function upperCaseFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
}
function getLastSentence(text) {
  if (!text) return "";
  text = text.replace("\n\nBy the way, you can tell me your email address at any time and I'll add it to our newsletter.", "").replace("\n\nBabes, Gunlom Falls is closed year-round for swimming and hiking until further notice.", "");
  const sentences = text.match(/[^.!?]+[.!?]+/g);
  const lastSentence = sentences[sentences.length - 1];
  return lowerCaseFirstLetter(lastSentence.replace(/[\r\n]/g, "").replace("So, back to where we left off, ", "").replace("So, ", "").trim())

}
