import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
// import * as chatActions from "redux/actions/chats";
import * as actions from "redux/actions/chats";
import MarkDown from "components/MarkDown";
import Avatar from "components/Avatar";
import useResponsive from "hooks/useResponsive";
// import { formatOptions, saveState, generateGUID } from "utils";
// import { generalChats } from "data";
// import { setToken } from "redux/services";

const Messages = ({
  data,
  answer,
  voice,
  selectedOptions,
  setSelectedOptions,
  handleMultiOptionClick,
  handleConfirmEmailClick,
  setQuery,
  prevOptions,
  reset,
  isLargeMobile
}) => {

  const messagesEndRef = useRef(null);
  const dispatch = useDispatch();
  const { isMobile } = useResponsive();
  const [options, setOptions] = useState(!prevOptions ? [] : prevOptions);
  // const [chatId, setChatId] = useState();
  const [streamingAnswer, setStreamingAnswer] = useState(null);

  useEffect(() => {
    setOptions(!prevOptions ? [] : prevOptions);
  }, [prevOptions]);

  const handleOptionsClick = (option) => {
    // setChatId(chatId);
    const optionIndex = options?.findIndex(
      (selectedOption) => selectedOption.value === option.value
    );
    if (optionIndex === -1) {
      setOptions([...options, option]);
    } else {
      const updatedOptions = [...options];
      updatedOptions.splice(optionIndex, 1);
      setOptions(updatedOptions);
    }
    handleMultiOptionClick(option);
  };

  // useEffect(() => {
  //   const conversationId = generateGUID();
  //   const chat = generalChats.find((chat) => chat.id === chatId + 1);
  //   const chatOneOptions = options?.filter((option) => option.chat === 1);
  //   const chatTwoOptions = options?.filter((option) => option.chat === 2);
  //   if (chatId === 1 && selectedOptions.length) {
  //     setOptions(chatOneOptions);
  //     setSelectedOptions([]);
  //     setToken("conversation-id", conversationId);
  //   }

  //   if (chat && streamingAnswer) {
  //     dispatch(
  //       chatActions.generalAnswer({
  //         bot: chat,
  //         options: options,
  //         chatId: chatId,
  //         chatOneLength: chatOneOptions.length,
  //         chatTwoLength: chatTwoOptions.length,
  //       })
  //     );
  //   }

  //   const query = `I am a looking to holiday in the NT in ${formatOptions(chatTwoOptions)}. I like ${formatOptions(chatOneOptions)}. I am a ${formatOptions(
  //     selectedOptions
  //   )} type of traveller. Recommend what I should visit in the Northern Territory.`;
  //   setQuery(query);
  //   saveState("options", options);
  // }, [
  //   streamingAnswer,
  //   options,
  //   chatId,
  //   dispatch,
  //   setQuery,
  //   selectedOptions,
  //   setSelectedOptions,
  // ]);

  useEffect(() => {
    const doWork = async () => {
      await new Promise(resolve => setTimeout(resolve, Math.floor(Math.random() * (100 - 10 + 1)) + 10));
      const stream = streamingAnswer;
      if (streamingAnswer?.streamMessage && streamingAnswer?.streamMessage?.length) {
        const tmp = stream?.streamMessage.split(" ");
        const update = tmp.shift();
        dispatch(actions.generalAnswerUpdate({ ...stream, streamMessage: tmp.join(" "), message: stream.message + " " + update, isLoading: true }));
      } else {
        if (stream) {
          dispatch(actions.generalAnswerSuccess());
        }
      }
    }
    doWork();
  }, [streamingAnswer, dispatch, setStreamingAnswer]);
  useEffect(() => {
    // dispatch(actions.generalAnswerUpdate(tmp));
  }, [dispatch]);

  const scrollToBottom = (type) => {
    messagesEndRef.current?.scrollIntoView({ behavior: type, block: 'nearest', inline: 'start' });
  };

  useEffect(() => {
    const last = data[data.length - 1];
    // console.log(last);
    if (last.streamMessage || last.isLoading) {
      setStreamingAnswer(last);
    }
  }, [data, setStreamingAnswer]);

  useEffect(() => {

    if (data.length > 1) {
      scrollToBottom("smooth");
    }
  }, [data, isLargeMobile]);

  useEffect(() => {

    if (answer.length > 1) {
      scrollToBottom("auto");
    }
  }, [answer]);

  const messagesStyles = "text-messages-gray text-messages";

  return (
    <div
      className="overflow-y-scroll box-border w-[calc(100%_+_4px)]"
      style={{ height: isMobile ? "calc(100% - 100px)" : "calc(100% - 155px)" }}
    >{data.map((msg, _idx) => {
      if (msg?.isBot) {
        const active = _idx === data.length - 1;
        return (
          <div
            key={`${msg?.message}_${_idx}`}
            className="w-full border-b border-[#DCDBDB] bg-message-bg"
          >
            <div className="flex gap-[10px] md:gap-5 justify-start pt-8 md:pt-5 pb-[18px] px-4 md:px-[51.04px]">
              <Avatar
                idx={_idx}
                id={msg?.id}
                src={msg?.avatar}
                voice={voice}
                isMobile={isMobile}
                isLoading={msg?.isLoading}
                className="w-8 h-8 md:w-[45px] md:h-[45px]"
              />

              <div>
                {msg?.message ? (
                  <div className={messagesStyles}>
                    <MarkDown link={true} >{msg?.message}</MarkDown>{msg?.isLoading && <div className="float-left animate-gpt w-[15px] h-[15px] bg-[#111827] rounded-full"></div>}
                  </div>
                ) : answer ? (
                  <div className={messagesStyles}>
                    <MarkDown link={true}>{answer}</MarkDown>
                  </div>
                ) : (
                  <div className="float-left animate-gpt w-[15px] h-[15px] bg-[#111827] rounded-full"></div>
                )}
                {!msg?.isLoading && msg?.options && (
                  <div className="flex flex-wrap gap-2.5 mt-5">
                    {!msg?.multiSelect ? (
                      <>
                        {msg?.options?.map((option) => (
                          <div
                            className={`cursor-pointer text-brand-blue text-optionsmb lg:text-options  py-2 lg:py-1  px-5 rounded-[50px] shrink-0 transition-colors hover:bg-brand-blue/5 ${options?.some(
                              (selectedOption) =>
                                selectedOption.value === option.value
                            )
                              ? "border border-brand-blue bg-brand-blue/5"
                              : "bg-white"
                              } ${active ? "cursor-pointer" : "pointer-events-none"}`}
                            key={option.value}
                            onClick={() => msg?.emailConfirm ? (msg?.disabled ? undefined : handleConfirmEmailClick(option, msg.id, msg.email)) : handleOptionsClick(option, msg.id)}
                          >
                            {option.title}
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        {!msg?.isLoading && msg?.options?.map((option) => (
                          <div
                            className={` text-brand-blue text-optionsmb lg:text-options  py-2 lg:py-1  px-5 rounded-[50px] shrink-0 transition-colors hover:bg-brand-blue/5 ${prevOptions?.some(
                              (selectedOption) =>
                                selectedOption.value === option.value
                            ) || options?.some(
                              (selectedOption) =>
                                selectedOption.value === option.value
                            )
                              ? "border border-brand-blue bg-brand-blue/5"
                              : "bg-white"
                              } ${active ? "cursor-pointer" : "pointer-events-none"}`}
                            key={option.value}
                            onClick={() => active ? handleOptionsClick(option) : null}
                          >
                            {option.title}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

          </div>
        );
      } else {
        return (
          <div key={`${msg?.message}_${_idx}`}>
            <div className="flex items-center justify-start w-full gap-[10px] md:gap-[25px] px-4 md:px-[51.04px] py-5 bg-white">
              <Avatar
                src={msg?.avatar}
                className="w-8 h-8 md:w-[45px] md:h-[45px]"
              />
              {!msg?.isLoading && msg?.options ? (
                <div className="flex flex-wrap items-center gap-[5px]">
                  {msg?.options?.map((option) => (
                    <button
                      className="text-brand-blue text-optionsmb lg:text-options py-2 lg:py-1 px-5 rounded-[50px] shrink-0 border border-brand-blue bg-brand-blue/5"
                      key={option.value}
                    >
                      {option.title}
                    </button>
                  ))}
                </div>
              ) : (
                <div className="text-messages text-messages-gray">
                  <MarkDown>{msg?.message}</MarkDown>
                </div>
              )}
            </div>
          </div>
        );
      }
    })}

      <div ref={messagesEndRef} />
    </div>
  );
};

export default Messages;
