import React from "react";

const MarkDown = ({ link, children }) => {
  const formatText = (text) => {
    // Use a regular expression to find numbers followed by a colon
    const regex = /(\d+\.\s.*?):/g;

    // Replace matched patterns with the same pattern wrapped in <strong> tags
    const formattedText = text
      ? text.replace(regex, "<strong>$1:</strong>").replace(/[\u{10000}-\u{10FFFF}]/gu, (char) => `&#${char.codePointAt(0)};`)
      : text;

    return formattedText;
  };

  const style = {
    whiteSpace: "pre-line", // Use pre-line to preserve line breaks
  };

  const formattedHTML = addLinksToText(formatText(children), link);
  // const formattedHTML = formatText(children);
  return (
    <div style={style} dangerouslySetInnerHTML={{ __html: formattedHTML }} />
  );
};

export default MarkDown;

function addLinksToText(text, link = false) {
  if (!link) return text;
  const uniqueKeys = [];

  // Sorting the keywords by length to replace longer phrases first
  keywordData.sort((a, b) => b[0].length - a[0].length);

  // Replace each keyword in the text with a unique key
  keywordData.forEach((pair, index) => {
    const [keyword, link] = pair;
    const uniqueKey = `<KEY${index}>`;
    const regex = new RegExp(keyword, 'gi');
    text = text.replace(regex, uniqueKey);
    uniqueKeys.push({ uniqueKey, keyword, link });
  });

  // Replace unique keys with their respective links
  uniqueKeys.forEach(({ uniqueKey, keyword, link }) => {
    const regex = new RegExp(uniqueKey, 'g');
    text = text.replace(regex, `<a href="${link}" target="_blank">${keyword}</a>`);
  });

  return text;
}

const keywordData = [
  ["northernterritory.com", "https://northernterritory.com"],
  ["deals", "https://northernterritory.com/deals-and-offers"],
  ["permits", "https://northernterritory.com/plan/useful-information/passes-and-permits"],
  ["Park Pass", "https://northernterritory.com/plan/useful-information/passes-and-permits"],
  ["park pass", "https://northernterritory.com/plan/useful-information/passes-and-permits"],
  ["Parks Pass", "https://northernterritory.com/plan/useful-information/passes-and-permits"],
  ["parks pass", "https://northernterritory.com/plan/useful-information/passes-and-permits"],
  ["park passes", "https://northernterritory.com/plan/useful-information/passes-and-permits"],
  ["parks passes", "https://northernterritory.com/plan/useful-information/passes-and-permits"]
]

// const keywordData = [
//   ["Uluru-Kata Tjuta National Park", "https://northernterritory.com/uluru-and-surrounds/see-and-do/uluru-kata-tjuta-national-park"],
//   ["Uluru", "https://northernterritory.com/uluru-and-surrounds/destinations/uluru"],
//   ["Darwin", "https://northernterritory.com/darwin-and-surrounds/destinations/darwin"],
//   ["Litchfield National Park", "https://northernterritory.com/darwin-and-surrounds/destinations/litchfield-national-park"],
//   ["Kings Canyon", "https://northernterritory.com/uluru-and-surrounds/destinations/watarrka-national-park"],
//   ["Kakadu National Park", "https://northernterritory.com/kakadu-and-surrounds/destinations/kakadu-national-park"],
//   ["Kakadu", "https://northernterritory.com/kakadu-and-surrounds/destinations/kakadu-national-park"],
//   ["Tiwi Islands", "https://northernterritory.com/darwin-and-surrounds/destinations/tiwi-islands"],
//   ["Nitmiluk National Park", "https://northernterritory.com/katherine-and-surrounds/destinations/nitmiluk-national-park"],
//   ["Nitmiluk Gorge", "https://northernterritory.com/katherine-and-surrounds/destinations/nitmiluk-national-park"],
//   ["Katherine Gorge", "https://northernterritory.com/katherine-and-surrounds/destinations/nitmiluk-national-park"],
//   ["Mataranka", "https://northernterritory.com/katherine-and-surrounds/destinations/mataranka-thermal-pool"],
//   ["Alice Springs", "https://northernterritory.com/alice-springs-and-surrounds/destinations/alice-springs"],
//   ["West MacDonnell Ranges", "https://northernterritory.com/alice-springs-and-surrounds/destinations/tjoritja--west-macdonnell-national-park"],
//   ["West MacDonnell National Park", "https://northernterritory.com/alice-springs-and-surrounds/destinations/tjoritja--west-macdonnell-national-park"],
//   ["Tjoritja", "https://northernterritory.com/alice-springs-and-surrounds/destinations/tjoritja--west-macdonnell-national-park"],
//   ["the Red Centre", "https://northernterritory.com/the-red-centre"],
//   ["the Top End", "https://northernterritory.com/the-top-end"],
//   ["Kata Tjuta", "https://northernterritory.com/uluru-and-surrounds/destinations/kata-tjuta-the-olgas"],
//   ["The Olgas", "https://northernterritory.com/uluru-and-surrounds/destinations/kata-tjuta-the-olgas"],
//   ["Katherine Hot Springs", "https://northernterritory.com/katherine-and-surrounds/see-and-do/katherine-hot-springs"],
//   ["Katherine", "https://northernterritory.com/katherine-and-surrounds/destinations/katherine"],
//   ["Maguk", "https://northernterritory.com/kakadu-and-surrounds/destinations/maguk"],
//   ["Karlu Karlu", "https://northernterritory.com/tennant-creek-and-barkly-region/destinations/karlu-karlu--devils-marbles-conservation-reserve"],
//   ["Devils Marbles", "https://northernterritory.com/tennant-creek-and-barkly-region/destinations/karlu-karlu--devils-marbles-conservation-reserve"],
//   ["Buley Rockhole", "https://northernterritory.com/darwin-and-surrounds/see-and-do/buley-rockhole"],
//   ["Bitter Springs", "https://northernterritory.com/katherine-and-surrounds/see-and-do/bitter-springs"],
//   ["Arnhem Land", "https://northernterritory.com/arnhem-land"],
//   ["Florence Falls", "https://northernterritory.com/darwin-and-surrounds/see-and-do/florence-falls"],
//   ["Wangi Falls", "https://northernterritory.com/darwin-and-surrounds/see-and-do/wangi-falls"],
//   ["Yellow Water", "https://northernterritory.com/kakadu-and-surrounds/destinations/yellow-water"],
//   ["Berry Springs Nature Park", "https://northernterritory.com/darwin-and-surrounds/see-and-do/berry-springs-nature-park"],
//   ["Berry Springs", "https://northernterritory.com/darwin-and-surrounds/see-and-do/berry-springs-nature-park"],
//   ["Mary River", "https://northernterritory.com/darwin-and-surrounds/destinations/mary-river"],
//   ["Glen Helen Gorge", "https://northernterritory.com/alice-springs-and-surrounds/see-and-do/glen-helen-gorge"],
//   ["Glen Helen", "https://northernterritory.com/alice-springs-and-surrounds/see-and-do/glen-helen-gorge"],
//   ["Rainbow Valley", "https://northernterritory.com/alice-springs-and-surrounds/destinations/rainbow-valley"],
//   ["Ubirr", "https://northernterritory.com/kakadu-and-surrounds/destinations/ubirr"],
//   ["East MacDonnell Ranges", "https://northernterritory.com/alice-springs-and-surrounds/destinations/east-macdonnell-ranges"],
//   ["Ellery Creek Big Hole", "https://northernterritory.com/alice-springs-and-surrounds/see-and-do/ellery-creek-big-hole"],
//   ["Simpsons Gap", "https://northernterritory.com/alice-springs-and-surrounds/see-and-do/simpsons-gap"],
//   ["Twin Falls Gorge", "https://northernterritory.com/kakadu-and-surrounds/destinations/twin-falls-gorge"],
//   ["Cutta Cutta Caves", "https://northernterritory.com/katherine-and-surrounds/see-and-do/cutta-cutta-caves-nature-park"],
//   ["Tennant Creek", "https://northernterritory.com/tennant-creek-and-barkly-region"],
//   ["Ormiston Gorge", "https://northernterritory.com/alice-springs-and-surrounds/see-and-do/ormiston-gorge"],
//   ["Redbank Gorge", "https://northernterritory.com/alice-springs-and-surrounds/see-and-do/redbank-gorge"],
//   ["Elsey National Park", "https://northernterritory.com/katherine-and-surrounds/see-and-do/elsey-national-park"],
//   ["Jim Jim Falls", "https://northernterritory.com/kakadu-and-surrounds/destinations/jim-jim-falls"],
//   ["Greenant Creek Walk", "https://northernterritory.com/darwin-and-surrounds/see-and-do/greenant-creek-walk--tjaetaba-falls"],
//   ["Tjaetaba Falls", "https://northernterritory.com/darwin-and-surrounds/see-and-do/greenant-creek-walk--tjaetaba-falls"],
//   ["Wagait Beach", "https://northernterritory.com/darwin-and-surrounds/destinations/wagait-beach"],
//   ["Tolmer Falls", "https://northernterritory.com/darwin-and-surrounds/see-and-do/tolmer-falls"],
//   ["The Lost City - Litchfield National Park", "https://northernterritory.com/darwin-and-surrounds/see-and-do/the-lost-city--litchfield-national-park"],
//   ["Leliyn", "https://northernterritory.com/katherine-and-surrounds/see-and-do/leliyn-edith-falls"],
//   ["Edith Falls", "https://northernterritory.com/katherine-and-surrounds/see-and-do/leliyn-edith-falls"],
//   ["Borroloola ", "https://northernterritory.com/katherine-and-surrounds/destinations/borroloola"],
//   ["Standley Chasm", "https://northernterritory.com/alice-springs-and-surrounds/see-and-do/standley-chasm--angkerle--atwatye"],
//   ["Burrungkuy", "https://northernterritory.com/kakadu-and-surrounds/destinations/burrungkuy-rock-art-site"],
//   ["Nourlangie", "https://northernterritory.com/kakadu-and-surrounds/destinations/burrungkuy-rock-art-site"],
//   ["Simpson Desert", "https://northernterritory.com/alice-springs-and-surrounds/destinations/simpson-desert"],
//   ["Bamboo Creek Tin Mine", "https://northernterritory.com/darwin-and-surrounds/see-and-do/bamboo-creek-tin-mine"],
//   ["Walker Creek", "https://northernterritory.com/darwin-and-surrounds/see-and-do/walker-creek"],
//   ["Daly River", "https://northernterritory.com/katherine-and-surrounds/destinations/daly-river"],
//   ["Finke Gorge National Park", "https://northernterritory.com/alice-springs-and-surrounds/destinations/finke-gorge-national-park"],
//   ["Motor Car Falls", "https://northernterritory.com/kakadu-and-surrounds/see-and-do/motor-car-falls"],
//   ["Serpentine Gorge", "https://northernterritory.com/alice-springs-and-surrounds/see-and-do/serpentine-gorge"],
//   ["Limmen National Park", "https://northernterritory.com/katherine-and-surrounds/see-and-do/limmen-national-park"],
//   ["Hermannsburg ", "https://northernterritory.com/alice-springs-and-surrounds/destinations/hermannsburg"],
//   ["Groote Eylandt", "https://northernterritory.com/arnhem-land/destinations/groote-eylandt"],
//   ["Nhulunbuy", "https://northernterritory.com/arnhem-land/destinations/nhulunbuy-gove"],
//   ["Blyth Homestead", "https://northernterritory.com/darwin-and-surrounds/see-and-do/blyth-homestead"],
//   ["Birthday Waterhole", "https://northernterritory.com/alice-springs-and-surrounds/see-and-do/birthday-waterhole"],
//   ["Trephina Gorge", "https://northernterritory.com/alice-springs-and-surrounds/see-and-do/trephina-gorge-nature-park"],
//   ["Gove", "https://northernterritory.com/arnhem-land/destinations/nhulunbuy-gove"],
//   ["Jatbula Trail", "https://northernterritory.com/katherine-and-surrounds/see-and-do/jatbula-trail"],
//   ["Larapinta Trail", "https://northernterritory.com/alice-springs-and-surrounds/see-and-do/larapinta-trail"],
//   ["Red Centre Way", "https://northernterritory.com/drive/red-centre-way"],
//   ["Natures Way", "https://northernterritory.com/drive/natures-way"],
//   ["Explorers Way", "https://northernterritory.com/drive/explorers-way"],
//   ["Outback Way", "https://northernterritory.com/drive/outback-way"],
//   ["Savannah Way", "https://northernterritory.com/drive/savannah-way"],
//   ["Overlanders Way", "https://northernterritory.com/drive/overlanders-way"],
//   ["Binns Track", "https://northernterritory.com/drive/binns-track"],
//   ["NT Parks Pass", "https://northernterritory.com/plan/useful-information/passes-and-permits"],
//   ["Kakadu Park Pass", "https://parksaustralia.gov.au/kakadu/plan/passes/"],
//   ["Bonza Airlines", "https://northernterritory.com/promotions/bonza"],
//   ["BASSINTHEGRASS", "https://northernterritory.com/darwin-and-surrounds/events/bassinthegrass"],
//   ["Barunga Festival", "https://northernterritory.com/katherine-and-surrounds/events/barunga-festival"],
//   ["Darwin Festival", "https://northernterritory.com/darwin-and-surrounds/events/darwin-festival"],
//   ["Red CentreNATS", "https://northernterritory.com/alice-springs-and-surrounds/events/red-centrenats"],
//   ["Million Dollar Fish", "https://northernterritory.com/darwin-and-surrounds/events/million-dollar-fish-season-9"],
//   ["Parrtjima", "https://northernterritory.com/alice-springs-and-surrounds/events/parrtjima-2024---a-festival-in-light"],
//   ["Finke Desert Race", "https://northernterritory.com/alice-springs-and-surrounds/events/tatts-finke-desert-race"],
//   ["Beanie Festival", "https://northernterritory.com/alice-springs-and-surrounds/events/alice-springs-beanie-festival-inc"],
//   ["Darwin Fringe Festival", "https://northernterritory.com/darwin-and-surrounds/events/2024-darwin-fringe-festival"],
//   ["Henley on Todd", "https://northernterritory.com/alice-springs-and-surrounds/events/henley-on-todd-regatta"],
//   ["FabALICE Festival", "https://northernterritory.com/alice-springs-and-surrounds/events/fabalice-festival"]
// ];
