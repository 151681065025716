import cookie from "react-cookies";

export const setToken = (name, token) => {
  return cookie.save(name, token, { path: "/" });
};

export const getToken = (name) => {
  return cookie.load(name) || null;
};

export const removeToken = (name) => {
  return cookie.remove(name, { path: "/" });
};
