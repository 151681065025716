import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "redux/actions/auth";

import Loader from "components/Loader";
import Unavailable from "components/Unavailable";
import General from "./General";


const Pages = () => {
  const dispatch = useDispatch();
  const { checkingAuth, user, serviceAvailable } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!checkingAuth && !user) {
      dispatch(authActions.login());
    } else {
      dispatch(authActions.getUser());
    }
  }, [dispatch, checkingAuth, user]);

  if (checkingAuth) return <Loader />;
  if (!serviceAvailable) return <Unavailable />;
  if (user)
    return (
      <General />
    );
  else
    return <Loader />;
};

export default Pages;
