//import Actions from "components/Avatar/Actions";

const Avatar = ({ id, src, className }) => {
  return (
    <div className={`rounded-sm overflow-hidden shrink-0 ${className}`}>
      <img
        id={id}
        src={src}
        alt=""
        className="object-cover w-full h-full aspect-square"
      />

    </div>
  );
};

export default Avatar;
