import * as constants from "redux/constants";
import * as services from "redux/services";

export const login = () => async (dispatch) => {

  try {
    const conversation_id = crypto.randomUUID();
    dispatch({ type: constants.LOGIN_REQUEST });
    if (conversation_id) {
      services.setToken("conversation-id", conversation_id);
      dispatch({ type: constants.LOGIN_SUCCESS, payload: conversation_id });
      dispatch(getUser());
    } else {
      dispatch({ type: constants.LOGIN_FAIL });
    }
  } catch (err) {
    console.log("login", err);
    dispatch({ type: constants.LOGIN_FAIL });
  }
};

export const checkService = async (dispatch) => {

  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/state`;
  const checkResponse = await fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
  })
  const enabled = await checkResponse.json();

  if (checkResponse.status !== 200 || !enabled.enabled) {
    dispatch({ type: constants.SERVICE_UNAVAILABLE });
    return false;
  }
  return true;
}

export const goOffline = async (dispatch) => {
  dispatch({ type: constants.SERVICE_UNAVAILABLE });
}
export const getUser = () => async (dispatch) => {
  try {
    // check that service is working or not
    const available = await checkService(dispatch);
    if (!available) return;
    if (services.getToken("conversation-id")) {
      dispatch({
        type: constants.GET_USER_SUCCESS,
        payload: services.getToken("conversation-id"),
      });
    } else {
      dispatch({ type: constants.GET_USER_FAIL });
      setTimeout(() => { dispatch(login()); }, 10000);
    }
  } catch (err) {
    console.log("Service not available", err);
    dispatch({ type: constants.GET_USER_FAIL });
    setTimeout(() => { dispatch(login()); }, 10000);
  }
};
