import cryptoJs from "crypto-js";

export const getCurrentTimeStamp = (d) => {
  if (d) new Date(d).toLocaleString();
  return new Date().toLocaleString();
};

export const getEncryptedData = (data) => {
  const encryptedData = cryptoJs.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_ENCRYPTION_KEY
  ).toString();
  return { encryptedData };
};

export const loadState = (state) => {
  try {
    const serializedState = localStorage.getItem(state);
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    console.log("saveState", err);
  }
};

export const formatOptions = (options) => {
  const optionsValues = options?.map((option) => option.value);
  if (optionsValues) {
    return new Intl.ListFormat("en-GB", {
      style: "short",
      type: "conjunction",
    }).format(optionsValues);
  }
};

export function generateGUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}


export const subscribeEmail = async (email) => {

  // fire data layer event for GA
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({ event: "eDMSignup_Submit" });
  }

  var customfields = [];
  customfields.push({ key: "source", value: "chatnt" });

  await fetch("https://auth.northernterritory.com/Signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      scfs: customfields,
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      if (data && (data === true || data.success === true)) {
        if (typeof window._satellite == "object") {
          if (data.userID && window.digitalData) {
            window.digitalData.user = { emailID: data.userID };
            window._satellite.track("Signup");
          }
          window._satellite.track("newsletterSubscription");
        }
      } else {
        console.log(data);
      }
    })
    .catch((error) => {
      console.error(
        "There was a problem with the fetch operation:",
        error
      );
    });
  return true;
}