import { Stream } from "@cloudflare/stream-react";
import { useEffect, useState, useRef } from "react";
import pauseSVG from "assets/video-pause.svg";
import playSVG from "assets/video-play.svg";
const VideoPlayer = ({
  currentVideoIndex,
  videoPlaylist,
}) => {
  const videoRef = useRef(null);
  const [playVideo, setPlayVideo] = useState(true);
  const [videoSrc, setVideoSrc] = useState(videoPlaylist[currentVideoIndex]);

  useEffect(() => {
    setVideoSrc(videoPlaylist[currentVideoIndex])
  }, [currentVideoIndex, videoPlaylist, setVideoSrc]);
  useEffect(() => {
    if (playVideo) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [playVideo, videoRef]);

  const playPauseVideo = () => {
    setPlayVideo(!playVideo);
  };

  return (
    <div className="relative w-full h-[320px] lg:h-full overflow-hidden lg:order-2  flex items-center">
      <Stream
        streamRef={videoRef}
        onEnded={() => {
          videoRef.current?.play();

        }}
        src={videoSrc}
        className="w-full h-full lazyload-wrapper"
        muted
        autoplay
      />
      <button className="absolute bottom-4 right-4 play-pause" onClick={playPauseVideo}>
        <div className="flex items-center justify-center w-8 h-8 bg-white rounded-full">
          <img
            src={playVideo ? pauseSVG : playSVG}
            alt="Video play pause icon"
          />
        </div>
      </button>
    </div>
  );
};

export default VideoPlayer;
