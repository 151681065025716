import { configureStore } from "@reduxjs/toolkit";
import middleware from "./middleware";
import rootReducer from "redux/reducers";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
});
// The store now has redux-thunk added and the Redux DevTools Extension is turned on

export default store;
