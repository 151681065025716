export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const GET_CHATS_REQUEST = "GET_CHATS_REQUEST";
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS";
export const GET_CHATS_FAIL = "GET_CHATS_FAIL";

export const GET_AUDIO_REQUEST = "GET_AUDIO_REQUEST";
export const GET_AUDIO_SUCCESS = "GET_AUDIO_SUCCESS";
export const GET_AUDIO_FAIL = "GET_AUDIO_FAIL";

export const GENERAL_PRODUCT_REQUEST = "GENERAL_PRODUCT_REQUEST";
export const GENERAL_PRODUCT_SUCCESS = "GENERAL_PRODUCT_SUCCESS";
export const GENERAL_PRODUCT_FAIL = "GENERAL_PRODUCT_FAIL";

export const CLEAR_CHATS = "CLEAR_CHATS";
export const GENERAL_STREAM_REQUEST = "GENERAL_STREAM_REQUEST";
export const GENERAL_STREAM_UPDATE = "GENERAL_STREAM_UPDATE";
export const GENERAL_STREAM_SUCCESS = "GENERAL_STREAM_SUCCESS";
export const GENERAL_STREAM_FAIL = "GENERAL_STREAM_FAIL";

export const SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE";

export const GENERAL_ANSWER = "GENERAL_ANSWER";
export const GENERAL_ANSWER_UPDATE = "GENERAL_ANSWER_UPDATE";
export const GENERAL_ANSWER_SUCCESS = "GENERAL_ANSWER_SUCCESS";

export const EMAIL_SUBSCRIPTION = "EMAIL_SUBSCRIPTION";
export const EMAIL_SUBSCRIPTION_REQUEST = "EMAIL_SUBSCRIPTION_REQUEST";
export const EMAIL_SUBSCRIPTION_DECLINED = "EMAIL_SUBSCRIPTION_DECLINED";
