import { saveState } from "utils";

const middleware = (store) => (next) => (action) => {
  if (
    action.type === "GENERAL_ANSWER" ||
    action.type === "GENERAL_STREAM_SUCCESS" ||
    action.type === "CLEAR_CHATS"
  ) {
    next(action);

    const { chats } = store.getState().chats;
    saveState("chats", chats);
  } else {
    next(action);
  }
};

export default middleware;
