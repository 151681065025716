
const Unavailable = () => {
    return (
        <div className="flex flex-col items-center justify-center w-full h-[100px] ">
            <div className="text-black m-[48px]">Sorry babe, there’s so many people asking me about summer at the moment – it’s hard to keep up! Be with you soon.
            </div>
        </div>
    );
};

export default Unavailable;
