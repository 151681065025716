import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "redux/store";

import Loader from "components/Loader";

import Pages from "pages";
import "./App.scss";

const App = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <Router>
          <Pages />
        </Router>
      </Suspense>
    </Provider>
  );
};

export default App;
