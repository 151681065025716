import { useEffect, useState } from "react";

const WIDTH = 768;
const LARGEWIDTH = 1024;

const useResponsive = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < WIDTH);
  const [isLargeMobile, setIsLargeMobile] = useState(window.innerWidth < LARGEWIDTH);

  // listener for mobile or desktop view changes.
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleResize = () => {
    if (window.innerWidth < WIDTH) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    if (window.innerWidth < LARGEWIDTH) {
      setIsLargeMobile(true);
    } else {
      setIsLargeMobile(false);
    }
  };

  return {
    isMobile,
    setIsMobile,
    isLargeMobile, setIsLargeMobile
  };
};

export default useResponsive;
