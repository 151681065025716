import ChatBubble from "components/ChatBubble";

const Loader = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <div className="text-white">Loading</div>
      <ChatBubble />
    </div>
  );
};

export default Loader;
