import * as utils from "utils";
import AbbieJPG from "assets/abbie.jpg";

export const err = "I am not sure, can you please provide me a right context!";
export const BOT_NAME = "AI";
export const USER_NAME = "USER";

export const initialChat = [
  {
    id: 1,
    name: BOT_NAME,
    avatar: AbbieJPG,
    multiSelect: true,
    message: "",
    streamMessage: "Let’s start planning an epic summer holiday to enliven your senses! \r\n<strong>Help me get to know you a little better, gorg! \r\nWhich of the below summer experiences get you most pumped?</strong> \r\nSelect from the options below, or send me a message.",
    isBot: true,
    isLoading: true,
    createdAt: utils.getCurrentTimeStamp(),
    options: [
      {
        title: "Flowing waterfalls",
        value: "Flowing waterfalls",
        chat: 1,
      },
      {
        title: "Outback beaches & refreshing waterholes",
        value: "Outback beaches and refreshing waterholes",
        chat: 1,
      },
      {
        title: "Epic wildlife experiences",
        value: "Epic wildlife experiences",
        chat: 1,
      },
      {
        title: "Red dirt & desert drives",
        value: "Red dirt and desert drives",
        chat: 1,
      }, {
        title: "Restaurants & bars",
        value: "Restaurants and bars",
        chat: 1,
      },
    ],
  },
];

export const emailChatRequest =
{
  id: "",
  name: BOT_NAME,
  avatar: AbbieJPG,
  isEmailRequest: true,
  message:
    "Just checking, you want me to subscribe <strong>[EMAIL]</strong> to the Tourism NT newsletter? Read Tourism NT’s <a href='https://northernterritory.com/find-out-more/privacy' target='_blank' rel='noopener noreferrer'>privacy policy</a> for more information.",
  isBot: true,
  createdAt: utils.getCurrentTimeStamp(),
  emailConfirm: true,
  disabled: false,
  isLoading: true,
  options: [
    {
      title: "Yes",
      value: "Yes",
      chat: "email",
    },
    {
      title: "No",
      value: "No",
      chat: "email",
    }]
};

export const emailChatSuccess =
{
  id: "",
  name: BOT_NAME,
  avatar: AbbieJPG,
  message:
    "Ok, I've subscribed you.",
  isBot: true,
  isLoading: true,
  createdAt: utils.getCurrentTimeStamp(),
}

export const emailChatDeclined =
{
  id: "",
  name: BOT_NAME,
  avatar: AbbieJPG,
  message:
    "No worries.",
  isBot: true,
  isLoading: true,
  createdAt: utils.getCurrentTimeStamp(),
}

export const generalChats = [
  // {
  //   id: 2,
  //   name: BOT_NAME,
  //   avatar: AbbieJPG,
  //   message:
  //     "are your thing? That’s great as summer in the NT has you covered. Slay! <br/><strong>When do you plan to visit?</strong> \r\nSelect from the options below:",
  //   isBot: true,
  //   multiSelect: true,
  //   isLoading: true,
  //   createdAt: utils.getCurrentTimeStamp(),
  //   options: [
  //     { title: "Sep-Nov", value: "Sep-Nov", chat: 2 },
  //     { title: "Dec-Jan", value: "Dec-Jan", chat: 2 },
  //     {
  //       title: "Feb-Mar",
  //       value: "Feb-Mar",
  //       chat: 2,
  //     },
  //     {
  //       title: "I don't know",
  //       value: "Summer",
  //       chat: 2,
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   name: BOT_NAME,
  //   avatar: AbbieJPG,
  //   message:
  //     "Ok, I want to know more about what you like, tell me \r\n<strong>What type of traveler are you?</strong> \r\nSelect one or more from the options below:",
  //   isBot: true,
  //   isLoading: true,
  //   createdAt: utils.getCurrentTimeStamp(),
  //   options: [
  //     { title: "Adventure", value: "Adventure" },
  //     { title: "Family", value: "Family" },
  //     {
  //       title: "Food-Explorer",
  //       value: "Food-Explorer",
  //     },
  //   ],
  //   multiSelect: true,
  // },
];
