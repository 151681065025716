import { useRef, useImperativeHandle, forwardRef } from "react";

const Footer = (
  { message, handleChange, handleKeyDown, inputOptions, handleReset, allowSubmission },
  ref
) => {

  const inputRef = useRef(null);

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useImperativeHandle(ref, () => ({
    focusInput: focusInput,
  }));

  const handleSend = () => {
    const customEvent = {
      keyCode: 13,
    };
    handleKeyDown(customEvent);
  };

  const haveInputOptions = inputOptions.length > 0;

  return (
    <>
      <button className="absolute inline-block rounded-lg border border-gray-300 font-medium text-sm tracking-tighter leading-normal max-md:py-[20px] max-md:bottom-0 max-md:left-[10px] p-[8px_12px] md:bottom-[70px] md:right-[32px] md:right-[51.04px] text-messages-button" onClick={() => { handleReset(); }}><div className="flex w-full gap-2 items-center justify-center"><svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="flex-shrink-0 icon-xs" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg><span className="max-md:hidden">Start over</span></div></button>
      <div className="mr-4 ml-[60px] md:mx-[51.04px] relative">
        <input
          ref={inputRef}

          type="text"
          name="msg"
          placeholder={!haveInputOptions ? "Send a message" : null}
          className={`w-full focus:outline-none bg-white rounded-xl shadow-footer-input h-14 placeholder-write-message placeholder-black/50 pr-[47px] font-medium px-[22.08px] as mb-[0px] border-[0px] text-messages-gray text-messageslg lg:text-messages ${haveInputOptions ? "caret-transparent" : ""
            }`}
          readOnly={!haveInputOptions ? false : true}
          value={!haveInputOptions ? message : ""}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        {inputOptions && (
          <div className="absolute left-[22.08px] flex items-center -translate-y-1/2 top-1/2 gap-[5px] overflow-hidden right-[50px]">
            {inputOptions.map((option) => (
              <div
                key={option.value}
                className="text-brand-blue text-optionsmb lg:text-options py-1 px-5 rounded-[50px] shrink-0 border border-brand-blue bg-brand-blue/5"
              >
                {option?.title}
              </div>
            ))}
          </div>
        )}
        <svg
          onClick={allowSubmission && (haveInputOptions || inputRef.current?.value !== "") ? handleSend : undefined}
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          className={`absolute right-[12px] -translate-y-1/2 top-1/2 ${haveInputOptions || inputRef.current?.value !== "" ? "cursor-pointer" : ""
            }`}
        >
          <path
            d="M24.0932 11.3468L12.6799 5.64018C5.0132 1.80018 1.86653 4.94685 5.70653 12.6135L6.86653 14.9335C7.19986 15.6135 7.19986 16.4002 6.86653 17.0802L5.70653 19.3868C1.86653 27.0535 4.99986 30.2002 12.6799 26.3602L24.0932 20.6535C29.2132 18.0935 29.2132 13.9068 24.0932 11.3468ZM19.7865 17.0002H12.5865C12.0399 17.0002 11.5865 16.5468 11.5865 16.0002C11.5865 15.4535 12.0399 15.0002 12.5865 15.0002H19.7865C20.3332 15.0002 20.7865 15.4535 20.7865 16.0002C20.7865 16.5468 20.3332 17.0002 19.7865 17.0002Z"
            className={allowSubmission &&
              (message.length !== 0 || haveInputOptions)
              ? "fill-brand-red"
              : "fill-[#CECECE]"
            }
          />
        </svg>
      </div>
    </>
  );
};

export default forwardRef(Footer);
