import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/chats";
import * as services from "redux/services";
import Footer from "components/Footer";
import VideoPlayer from "components/VideoPlayer";
import Messages from "pages/General/Messages";
import Loader from "components/Loader";
import { checkService, goOffline } from "redux/actions/auth";
import { formatOptions, generateGUID, loadState, saveState, subscribeEmail } from "utils";
import { generalChats } from "data";
import { setToken } from "redux/services";
import useResponsive from "hooks/useResponsive";

const videoPlaylist = [
  "15edfcf0f652d39ed8c7785124cf0882",
  "03c1069d41e0b1c78ec9fb79e2df9d64",
  "b4b030090d4d1ad6e8117352b64e4e8a",
  "5d67fb548efc466a93b4bffc20e1c036",
];
const mobilePlaylist = [
  "c3afaccdd6fad3293b6f4c263251fe4f",
  "66d58781a88683f1487b9e891e006bf0",
  "7ae957ffa916a8a41e22b049e63eee60",
  "15c1d1f0b78b65f62a1b98dd4694ab27"
]

const General = () => {
  const dispatch = useDispatch();
  const footerRef = useRef(null);

  const { chats, loading, sessionChecking } = useSelector(
    (state) => state.chats
  );

  //const [voice, setVoice] = useState(false);
  // const [audio, setAudio] = useState(false);

  const [message, setMessage] = useState("");
  const [reset, setReset] = useState(false);
  const [prevOptions, setPrevOptions] = useState([]);
  const [inputOptions, setInputOptions] = useState([]);

  const [query, setQuery] = useState("");
  const [numQuery, setNumQuery] = useState(0);
  const [answer, setAnswer] = useState("");
  const [streamingAnswer, setStreamingAnswer] = useState([]);
  const [allowSubmission, setAllowSubmission] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [randomVideoPlaylist, setRandomVideoPlaylist] = useState(0);

  const { isLargeMobile } = useResponsive();
  //const answerRef = useRef("");

  const handleMultiOptionClick = (option) => {
    const optionIndex = selectedOptions.findIndex(
      (selectedOption) => selectedOption.value === option.value
    );

    if (optionIndex === -1) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      const updatedOptions = [...selectedOptions];
      updatedOptions.splice(optionIndex, 1);
      setSelectedOptions(updatedOptions);
    }
    setMessage("");
    focusOnMessageInput();
  };

  useEffect(() => {

    setInputOptions(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    setRandomVideoPlaylist((isLargeMobile ? mobilePlaylist : videoPlaylist).sort(() => Math.random() - 0.5));

  }, [isLargeMobile]);

  useEffect(() => {
    setReset(true);
  }, [reset, setReset]);

  const focusOnMessageInput = () => {
    if (footerRef.current) {
      footerRef.current.focusInput();
    }
  };

  const handleChange = (e) => {
    const value = e?.target?.value;
    setMessage(value);

    setQuery(value);
  };

  const handleReset = (e) => {
    saveState("options", null);
    setPrevOptions([]);
    setSelectedOptions([]);
    dispatch(actions.clearChats());
    setReset(false);
  };
  function extractEmailFromString(text) {
    const emailRegex = /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;

    const foundEmail = text.match(emailRegex);
    if (foundEmail) {
      return foundEmail[0];
    } else {
      return false;
    }
  }
  const handleKeyDown = (e) => {
    //console.log(selectedOptions, chats);
    if (!allowSubmission) return;
    let dispatched = false;
    let queryToSend = "";



    if (selectedOptions?.length) {
      const option = selectedOptions[selectedOptions.length - 1];
      if (option.email) {
        const email = option.email;
        dispatched = true;

        setSelectedOptions([]);
        if (option.value === "Yes") {
          subscribeEmail(email).then((res) => {
            dispatch(actions.emailSubscription(email));
          });
        } else {
          dispatch(actions.emailSubscriptionDecline(email));
        }
      } else {


        // console.log("Have options, need to not send unless on last option");
        const options = [...prevOptions, ...selectedOptions];
        const conversationId = generateGUID();
        const chatId = chats[chats.length - 1].id;
        const chat = generalChats.find((chat) => chat.id === chatId + 1);
        const chatOneOptions = options?.filter((option) => option.chat === 1);
        const chatTwoOptions = options?.filter((option) => option.chat === 2);
        if (chatId === 1 && selectedOptions.length) {
          // setOptions(chatOneOptions);
          setToken("conversation-id", conversationId);
        }
        if (chat) {
          dispatched = true;
          dispatch(
            actions.generalAnswer({
              bot: chat,
              options: selectedOptions,
              chatId: chatId,
              chatOneLength: chatOneOptions?.length || 0,
              chatTwoLength: chatTwoOptions?.length || 0,
            })
          );
        }

        const query = `Tell me about some great ${formatOptions(chatOneOptions)}`;
        // const query = `I am looking to holiday in the NT${formatOptions(chatTwoOptions)}. I like ${formatOptions(chatOneOptions)}. Recommend what I should visit in the Northern Territory.`;
        // const query = `I am a looking to holiday in the NT in ${formatOptions(chatTwoOptions)}. I like ${formatOptions(chatOneOptions)}. I am a ${formatOptions(
        //   selectedOptions
        // )} type of traveller. Recommend what I should visit in the Northern Territory.`;
        setQuery(query);
        queryToSend = query;

        setPrevOptions(options);
        saveState("options", options);

        setSelectedOptions([]);
      }
    }

    if (!dispatched) {
      //console.log("no chat found, lets ask AI!")

      if (e?.keyCode === 13 && message?.trim() && !loading) {

        const email = extractEmailFromString(message?.trim());
        if (email) {
          dispatch(actions.emailSubscriptionRequest({ email: email, message: message }));
        } else {
          // Stream Chat

          dispatch(actions.generalStreamRequest(message));
          handleResponse(queryToSend || message);
        }

        setMessage("");
        focusOnMessageInput();
      } else if (e?.keyCode === 13 && selectedOptions && !loading) {
        dispatch(actions.generalStreamRequest(selectedOptions));
        focusOnMessageInput();
        handleResponse(queryToSend || message);
        setInputOptions([]);
      }
    }
  };

  const emailSubscription = (option, id, email) => {
    option.email = email;
    setSelectedOptions([option]);
    setMessage("");
    focusOnMessageInput();

  };
  const handleResponse = (query) => {

    checkService(dispatch);

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/conv`;
    const conversationId = services.getToken("conversation-id");

    // Define the data to be sent in the request body
    const requestData = {
      message: query,
      conversationId: conversationId,
    };

    setStreamingAnswer("");
    setAllowSubmission(false);
    // Make the API request
    fetch(apiUrl, {
      method: "POST",
      body: JSON.stringify(requestData),
      headers: {
        "Content-Type": "application/json",
        "X-ConversationId": conversationId,
      },
    })
      .then((response) =>
        response.json()
      ).then((data) => {

        if (data.control !== "ok") {
          goOffline(dispatch);
        } else {
          setNumQuery((numQuery) => numQuery + 1);
          if (data.message.toLowerCase().indexOf("gunlom") !== -1 && data.message.toLowerCase().indexOf("closed") === -1) {
            data.message += "\n\nBabes, Gunlom Falls is closed year-round for swimming and hiking until further notice.";
          }
          if (numQuery >= 5) {
            setNumQuery(0);
            if (!loadState("subscribed")) {
              data.message += "\n\nBy the way, you can tell me your email address at any time and I'll add it to our newsletter.";
            }
          }

          setStreamingAnswer(data.message.split(" "));
          return;
        }

      }).catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    const doWork = async () => {
      await new Promise(resolve => setTimeout(resolve, Math.floor(Math.random() * (100 - 10 + 1)) + 10));
      if (streamingAnswer.length) {
        const tmp = [...streamingAnswer];
        const update = tmp.shift();
        setAnswer((prevData) => prevData + " " + update);
        setStreamingAnswer(tmp);
      } else {
        if (answer) {
          const tmp = answer;
          if (tmp.length)
            dispatch(actions.generalStreamSuccess(tmp));
          setAnswer("");
          setAllowSubmission(true);
          setCurrentVideoIndex(
            (prevIndex) => (prevIndex + 1) % videoPlaylist.length
          );
        }
      }
    }
    doWork();
  }, [streamingAnswer, answer, dispatch, setAnswer, setStreamingAnswer]);
  useEffect(() => {
    dispatch(actions.generalStreamUpdate(answer));
  }, [answer, dispatch]);


  if (sessionChecking) return <Loader />;

  return (
    <div className="w-full shadow-lg lg:w-[800px] lg:h-[640px] h-full xl:w-[1204px] xl:h-[800px] bg-white grid lg:grid-cols-[1fr_37.5%] gap-0 mx-auto">

      {/* Video UI */}
      <VideoPlayer
        currentVideoIndex={currentVideoIndex}
        setCurrentVideoIndex={setCurrentVideoIndex}
        videoPlaylist={randomVideoPlaylist}
      />

      <div className="relative z-20  max-md:h-[calc(100vh_-_68px)] max-lg:h-[calc(100vh_-_320px)] overflow-hidden lg:order-1">
        <div className="absolute z-[-1] top-[0px] flex h-full w-full"><h2 className="text-4xl font-semibold text-center text-chatnt ml-auto mr-auto mb-[-80px] flex gap-2 items-center justify-center flex-grow">ChatNT</h2></div>

        {reset && <Messages
          data={chats}
          query={query}
          setQuery={setQuery}
          answer={answer}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          handleMultiOptionClick={handleMultiOptionClick}
          handleConfirmEmailClick={emailSubscription}
          prevOptions={prevOptions}
        />}

        <div className="absolute w-full bottom-[30px]">
          <Footer
            ref={footerRef}
            loading={loading}
            message={message}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
            handleReset={handleReset}
            allowSubmission={allowSubmission}
            inputOptions={inputOptions}
          />

        </div>
        <div className="absolute w-full bottom-0 px-0 py-2 text-center max-sm:text-disclaimer text-xs text-gray-600 md:px-[60px]"><span>ChatNT can make mistakes. Consider checking important information.</span></div>

      </div>
    </div>
  );
};

export default General;
