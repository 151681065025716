import * as constants from "redux/constants";

export const clearChats = () => async (dispatch) => {
  dispatch({ type: constants.CLEAR_CHATS });
};
export const generalStreamRequest = (message) => async (dispatch) => {
  dispatch({ type: constants.GENERAL_STREAM_REQUEST, payload: message });
};
export const generalStreamUpdate = (message) => async (dispatch) => {
  dispatch({ type: constants.GENERAL_STREAM_UPDATE, payload: message });
};

export const generalAnswer = (messages) => async (dispatch) => {
  dispatch({ type: constants.GENERAL_ANSWER, payload: messages });
};

export const generalAnswerUpdate = (messages) => async (dispatch) => {
  dispatch({ type: constants.GENERAL_ANSWER_UPDATE, payload: messages });
};
export const generalAnswerSuccess = (messages) => async (dispatch) => {
  dispatch({ type: constants.GENERAL_ANSWER_SUCCESS, payload: messages });
};

export const emailSubscription = (email) => async (dispatch) => {
  dispatch({ type: constants.EMAIL_SUBSCRIPTION, payload: email });
};
export const emailSubscriptionDecline = (email) => async (dispatch) => {
  dispatch({ type: constants.EMAIL_SUBSCRIPTION_DECLINED, payload: email });
};
export const emailSubscriptionRequest = (email) => async (dispatch) => {
  dispatch({ type: constants.EMAIL_SUBSCRIPTION_REQUEST, payload: email });
};
export const generalStreamSuccess = (message) => async (dispatch) => {
  try {
    dispatch({ type: constants.GENERAL_STREAM_SUCCESS, payload: message });
  } catch (err) {
    console.log("generalStreamSuccess", err);
    dispatch({ type: constants.GENERAL_STREAM_FAIL });
  }
};

export const getAudioSuccess = (data) => async (dispatch) => {
  try {
    dispatch({ type: constants.GET_AUDIO_SUCCESS, payload: data });
  } catch (err) {
    console.log("getAudioSuccess", err);
    dispatch({ type: constants.GET_AUDIO_FAIL });
  }
};
